import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';

function App() {
  const [pageData, setPageData] = useState('');
  var backgrounds = [
    'linear-gradient(to right, #1488cc, #2b32b2)',
    'linear-gradient(to top, #1488cc, #2b32b2)',
    'linear-gradient(to right, #ec008c, #fc6767)',
    'linear-gradient(to top, #ec008c, #fc6767)',
    'linear-gradient(to right, #cc2b5e, #753a88)',
    'linear-gradient(to top, #cc2b5e, #753a88)',
    'linear-gradient(to right, #2193b0, #6dd5ed)',
    'linear-gradient(to top, #2193b0, #6dd5ed)',
    'linear-gradient(to right, #e65c00, #f9d423)',
    'linear-gradient(to top, #77a1d3, #79cbca, #e684ae)',
    'linear-gradient(to bottom, #5433ff, #20bdff, #a5fecb)',
    'linear-gradient(to right, #5433ff, #20bdff, #a5fecb)'
    
    ]
function changeBg()  {
  var rand = backgrounds[Math.floor(Math.random() * backgrounds.length)];
    document.body.style.background = rand;  
}  


   useEffect(async ()=>{
    changeBg();
     const dataFetch = await fetch("https://canbaz.graphcdn.app", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({
            query: `
            query seePosts {
              posts {
                nodes {
                  id
                  title
                  postId
                  featuredImage {
                    node {
                      mediaItemUrl
                    }
                  }
                  content
                }
              }
            }
              
            `
        })
    })
    const json = await dataFetch.json();
    setPageData(json.data.posts.nodes[0]);
  }, [])
  return (
    <div className="content">
    {/* <h4>Ibrahim Canbaz</h4>
    <h5>Fullstack Developer</h5>
    
    <span>React, Angular</span><br />
    <span>Java, Node, Go</span><br />
    <span>ObjectiveC, React Native, Ionic</span><br />
    <span>Mongo, MySql, Oracle</span><br />
    <span>Electron</span><br />
    <span>I've been developing for <span class="roundy">10</span> years.</span><br />
    <span>♥ what i do...</span>
    <br /> */}
    {pageData.featuredImage && <img src={pageData.featuredImage.node.mediaItemUrl} width={200} />}
    <h4>{pageData.title}</h4>
    <div dangerouslySetInnerHTML={{ __html: pageData.content }} />
</div>
  );
}

export default App;
